import axios from 'axios'
import store from '../../store/index'



const instance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API_URL
});

const token = localStorage.getItem('accessToken');
instance.defaults.headers.common['Authorization'] = 'JWT ' + token;
instance.interceptors.request.use((config) => {
  store.commit('loading', true);
  return config;
}, (error) => {
  store.commit('loading', false);
  return Promise.reject(error);
});

instance.interceptors.response.use((response) => {
  store.commit('loading', false);
  return response;
}, (error) => {
  store.commit('loading', false);
  return Promise.reject(error);
});

export default instance

